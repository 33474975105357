import React from "react"
import Img from "gatsby-image"

export default ({ excerpt, title, image }) => (
  <div className="col-md-3">
    <div className="card" style={{ marginLeft: 0, marginRight: 0 }}>
      <Img
        fluid={image.childImageSharp.fluid}
        className="card-img-top"
        alt={title}
        title={title}
      />
      <div className="card-body">
        <p className="card-text">{excerpt}</p>
      </div>
    </div>
  </div>
)
