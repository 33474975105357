import React from "react"
import { Link, graphql } from "gatsby"
// import Img from "gatsby-image"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import Content from "../components/content"

// import icon1 from "../images/1_1024.jpg"
// import icon2 from "../images/2_1024.jpg"
// import icon3 from "../images/3_1024.jpg"
// import icon4 from "../images/4_1024.jpg"

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 718, maxHeight: 538) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "1_1024.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "2_1024.jpg" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "3_1024.jpg" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "4_1024.jpg" }) {
      ...fluidImage
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Executăm lucrări de proiectare / execuție rețea fibră optică"
      description="Flexibiliateta și stabilitatea fibrei optice sunt calități cheie care
          stau la baza dezvoltării rețelelor de mare viteză"
    />
    <Content>
      <div
        className="jumbotron text-center"
        style={{
          backgroundColor: "#FFF",
          marginBottom: 0,
        }}
      >
        <h1 className="display-6">Dezvoltăm rețele de fibră optică</h1>
        <p className="lead ">
          Flexibiliateta și stabilitatea fibrei optice sunt calități cheie care
          stau la baza dezvoltării rețelelor de mare viteză pentru
          infrastructura IT și de telecomunicații.
        </p>
        <hr className="my-4" />
        <Link
          className="btn btn-primary btn-lg"
          style={{
            backgroundColor: "#2CAF87",
            borderColor: "#2CAF87",
            margin: "0px auto",
            maxWidth: "240px",
            display: "block",
          }}
          href="#"
          to="/contact"
        >
          Solicitați oferta noastră
        </Link>
      </div>
    </Content>
    <div
      className="card-deck"
      style={{
        margin: `0 auto`,
        maxWidth: 1600,
        padding: `0px 1.0875rem 1.45rem`,
        paddingTop: 0,
        // backgroundColor: "red",
      }}
    >
      <Card
        image={data.imageOne}
        title="Execuție rețea fibră optică"
        excerpt="Execuție rețea - instalare fibră optică pe suport aerian cu instalare de stâlpi sau in canalizație
    prin realizarea de cămine / camerete și pozarea de țeavă PVC sau monotub HDPE"
      />
      <Card
        image={data.imageTwo}
        title="Instalare echipament"
        excerpt="Instalare echipamente active și pasive - fibră optică, securizări de scări de bloc"
      />
      <Card
        image={data.imageThree}
        title="Măsuratori optice"
        excerpt="Măsuratori optice – Optical Time Domain Reflectometer
      (urmarirea funcționării rețelei în parametrii optimi)"
      />
      <Card
        image={data.imageFour}
        title="Joncționări fibre optice"
        excerpt="Joncționări fibre optice, instalare enclousere,
        intervenții în cazul unor deranjamente (inlocuire cablu avariat,
        inlocuire stâlpi, inlocuire rame capac defecte), relocare rețea de cablu"
      />
    </div>
    <Content>
      <p className={"text-center"}>
        Investim în permanență în pregătirea angajaților și suntem pregătiți să
        răspundem oricărei provocări indiferent de complexitate.
      </p>
    </Content>
  </Layout>
)

export default IndexPage
